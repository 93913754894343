body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  margin-top: 50px;
}

.card-header {
  font-weight: bold;
}

.card-footer {
  font-weight: bold;
  text-align: center;
}

.card-footer p {
  margin-bottom: 10px;
}

.card-header p {
  margin-bottom: 10px;
}

.officer-name {
  width: 200px;
}

.officer-title {
  width: 200px;
  font-weight: bold;
}

td p {
  margin-bottom: 0px;
}

.lodge-city {
  margin-bottom: 15px;
}

input,
select,
textarea {
  max-width: 280px;
}

.jumbotron {
  margin-top: 30px;
  width: auto;
}

.lodgeName {
  font-size: 20px;
}

.center-text {
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width: 480px) {
  .firstHead {
    font-size: 24pt;
    font-weight: bold;
  }

  .secondHead {
    font-size: 20pt;
  }
}

@media screen and (max-width: 479px) {
  .firstHead {
    font-size: 12pt;
    font-weight: bold;
  }
}
